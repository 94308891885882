@use "sass:map";
@use "sass:math";
@use "@angular/material" as mat;
@use "./estilos/tema" as tema;
@use "./estilos/bottomSheet" as bottomSheet;
@use "./estilos/menu" as menu;
@use "./estilos/buttonToggle" as buttonToggle;
@use "./estilos/chips" as chips;
@use "./estilos/select" as select;
@use "./estilos/drawer" as drawer;

// Core
@include mat.core();

// Paletas
$letiende-primary: mat.define-palette(tema.$lt-primario);
$letiende-accent: mat.define-palette(tema.$lt-secundario, 500, 300, 800);
$letiende-warn: mat.define-palette(tema.$lt-advertencia, A200, A100, A700);
$lt-basico: tema.$lt-basico;

// Tipografía
$lt-tipografia: map.merge(
  mat.define-typography-config(),
  (
    body-1: tema.$body-1,
    body-2: tema.$body-2,
    body-3: tema.$body-3,
    body-4: tema.$body-4,
    body-5: tema.$body-5,
    heading-1: tema.$heading-1,
    heading-2: tema.$heading-2,
    heading-3: tema.$heading-3,
    heading-4: tema.$heading-4,
    caption-1: tema.$caption-1,
    caption-2: tema.$caption-2,
    caption-3: tema.$caption-3,
    especial-0: tema.$especial-0,
    especial-1: tema.$especial-1,
    especial-2: tema.$especial-2,
    especial-3: tema.$especial-3,
    especial-4: tema.$especial-4
  )
);
@include mat.typography-hierarchy($lt-tipografia);

// Tamaños
$tamanos: tema.$tamanos;

// Tema
$letiende-theme: mat.define-light-theme(
  (
    color: (
      primary: $letiende-primary,
      accent: $letiende-accent,
      warn: $letiende-warn
    ),
    typography: $lt-tipografia
  )
);
@include mat.all-component-themes($letiende-theme);
@include bottomSheet.tema($letiende-theme);
@include menu.tema($letiende-theme);
@include buttonToggle.tema($letiende-theme);
@include chips.tema($letiende-theme);
@include select.tema($letiende-theme);
@include drawer.tema($letiende-theme);

/* You can add global styles to this file, and also import other style files */

$naranja: map.get(tema.$lt-basico, naranja);

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Poppins, Roboto, "Helvetica Neue", sans-serif;
  color: map.get(tema.$lt-basico, marron);
  .contenedor + * {
    display: flex;
    width: 100vw;
    min-height: calc(100vh - map.get(tema.$tamanos, alto-pie) - map.get(tema.$tamanos, alto-navbar));
  }
  // Desplegable de Promociones
  .backPromo {
    background-color: rgba(#000, 0.6);
  }
  .producto {
    & > .imagen {
      display: flex;
      align-items: center;
      color: $naranja;
      margin-right: 8px;
    }
  }
  // Spacer genérico para elementos tipo flex
  .spacer {
    flex: 1 1 auto;
  }
  .vinculo {
    font-weight: 500;
    text-decoration: none;
    color: $naranja;
    &.externo {
      display: inline-flex;
      align-items: center;
      &::after {
        font-family: "Material Icons";
        content: "\e89e";
      }
    }
  }
  // Para el calendario de eventos
  .eventos {
    .calendario {
      .almanaque {
        .almanaque-dias {
          .dia {
            .mat-grid-tile-content {
              flex-direction: column;
              justify-content: flex-start;
            }
          }
        }
      }
    }
  }
  // Para la ficha de libros
  .ficha-libro {
    .libro {
      .contenido {
        .num-paginas {
          .icono {
            .fa-layers-counter {
              background-color: $naranja;
              transform: scale(0.5);
              right: -4px;
              top: -4px;
            }
          }
        }
      }
    }
  }
  // Para las opciones del autocomplete
  .opcion-autocomplete {
    font-size: 16px;
    min-height: 16px;
    .mdc-list-item__primary-text {
      display: flex;
      gap: 0.75em;
      max-height: 2em;
      width: 100%;
      .texto {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 100%;
      }
    }
  }
}
