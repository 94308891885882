@use "sass:map";
@use "@angular/material" as mat;
@use "tema" as tema;

@mixin color($theme) {
  $naranja: map.get(tema.$lt-basico, naranja);
  $amarillo: map.get(tema.$lt-basico, amarillo);
  $verde: map.get(tema.$lt-basico, verde);

  .selector-vista {
    .mat-button-toggle-group {
      border-radius: map.get(tema.$tamanos, border-radius) * 2;
      .mat-button-toggle {
        color: #fff;
        background-color: rgba(0, 0, 0, 0.25);
        .mat-button-toggle-button {
          .mat-button-toggle-label-content {
            display: flex;
            line-height: 1.75;
          }
        }
        &.mat-button-toggle-checked {
          background-color: $verde;
          .mat-button-toggle-button {
            .mat-button-toggle-label-content {
              .mat-mdc-option-pseudo-checkbox {
                display: none;
              }
              gap: 8px;
              &::after {
                content: "\e5ca";
                font-family: "Material Icons";
              }
            }
          }
        }
      }
    }
  }
}
@mixin typography($theme) {
  $typography-config: mat.get-typography-config($theme);
}
@mixin tema($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }
  $typography-config: mat.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
