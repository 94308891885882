@use "sass:map";
@use "@angular/material" as mat;
@use "tema" as tema;

@mixin color($theme) {
  $naranja: map.get(tema.$lt-basico, naranja);
  $amarillo: map.get(tema.$lt-basico, amarillo);
  $verde: map.get(tema.$lt-basico, verde);
  $marron: map.get(tema.$lt-basico, marron);

  .mat-drawer-container {
    width: 100%;
    height: 100%;
    &,
    .mat-drawer {
      background-color: $amarillo;
      position: absolute;
      .mdc-list {
        padding: 0;
        &:first-of-type {
          padding-top: 8px;
        }
        .mat-mdc-list-item {
          height: 32px;
          .mat-mdc-list-item-icon {
            margin-inline-end: 8px;
            color: $marron;
          }
          .mdc-list-item__primary-text {
            font-size: 18px;
            color: $marron;
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: space-between;
            .active {
              opacity: 0;
            }
          }
          &.mdc-list-item--activated {
            background-color: $verde;
            .mat-mdc-list-item-icon,
            .active {
              color: $amarillo;
            }
            .mdc-list-item__primary-text {
              color: #fff;
              .active {
                opacity: 1;
              }
            }
          }
        }
      }
    }
    .mat-drawer-content {
      padding: 0;
    }
  }
}
@mixin typography($theme) {
  $typography-config: mat.get-typography-config($theme);
}
@mixin tema($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }
  $typography-config: mat.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
