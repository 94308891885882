@use "sass:map";
@use "@angular/material" as mat;
@use "tema" as tema;

@mixin color($theme) {
  $naranja: map.get(tema.$lt-basico, naranja);
  $amarillo: map.get(tema.$lt-basico, amarillo);
  $verde: map.get(tema.$lt-basico, verde);
  $marron: map.get(tema.$lt-basico, marron);

  .selectores,
  .orden {
    .mat-mdc-form-field {
      width: 100%;
      .mat-mdc-text-field-wrapper {
        padding: 2px;
        height: 36px;
        max-height: 36px;
        .mat-mdc-form-field-flex {
          .mat-mdc-form-field-infix {
            width: 100%;
            min-width: auto;
            max-height: 36px;
            min-height: auto;
            padding: 0 8px;
            .mat-mdc-select {
              font-size: 16px;
            }
          }
          .mdc-floating-label {
            font-size: 16px;
            top: 18px;
          }
        }
      }
    }
  }
  .mat-mdc-option {
    &.filtro {
      font-size: 14px;
      min-height: 24px;
      line-height: 18px;
      padding: 4px 16px;
    }
  }
  .mat-mdc-option {
    &.ordenador {
      font-size: 18px;
      min-height: 32px;
      line-height: 18px;
      padding: 4px 16px;
    }
  }
}
@mixin typography($theme) {
  $typography-config: mat.get-typography-config($theme);
}
@mixin tema($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }
  $typography-config: mat.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
